* {
  color: black;
}

.Header {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.Header a {
  /* display block allows for the area around the link to be clickable  */
  display: block;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0.3rem;
}

a:hover {
  background-color: mediumblue;
  color: white;
}

.active {
  background-color: mediumblue;
  color: white;
}

/* 750px and down */
@media only screen and (max-width: 750px) {
  .Header a {
    font-weight: 300;
    font-size: 1.1rem;
    padding: 0.1rem;
  }
}
