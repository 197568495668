.Contact {
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.Email {
  height: 40%;
  width: 35%;
  padding: 0% 5%;
  /* border: solid 1px red; */
}

.services {
  height: 40%;
  padding: 0% 5%;
  /* border: solid 1px red; */
}

h2 {
  font-size: 2.2rem;
}

.gmail {
  font-size: 1.3rem;
}

.list {
  font-size: 1.3rem;
  line-height: 2rem;
}

/* 800px and down */
@media only screen and (max-width: 800px) {
  .Contact {
    flex-direction: column;
  }
  .Email {
    width: 60%;
    height: 30%;
    padding: 5% 5%;
  }
  .services {
    width: 60%;
    height: 25%;
    padding: 0% 5%;
  }
  h2 {
    font-size: 2rem;
  }
  .gmail {
    font-size: 1.5rem;
  }
  .list {
    font-size: 1.5rem;
  }
}

/* 750px and down */
@media only screen and (max-width: 750px) {
  .Contact {
    flex-direction: column;
  }
  .Email {
    width: 60%;
    height: 35%;
    padding: 0% 0%;
  }
  .services {
    height: 25%;
    width: 60%;
    padding: 0% 0%;
  }
  h2 {
    font-size: 1.2rem;
  }
  .gmail {
    font-size: 0.8rem;
  }
  .list {
    font-size: 0.8rem;
  }
}
