.Works {
  position: relative;
  top: 0; /*starts after 10vh*/
  width: 100vw;
  height: 100vh;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.container {
  padding: 0% 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 155px;
  /* border-bottom: 1px solid blue; */
}

.item {
  width: 100%;
  height: 90%;
  background-color: white;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border-bottom: 2px solid green; */
}

.top {
  padding: 0% 3%;
  /* border-bottom: 1px solid red; */
}

img {
  width: 22vh;
}

.imgLink {
  text-decoration: none !important;
  border: 0px !important;
  outline: none;
  border-width: 0px;
  outline-width: 0px;
  border-bottom: none;
}

.bottom {
  height: 75%;
  padding: 0 7%;
  line-height: 0.8rem;
  overflow: hidden;
  /* border-bottom: 1px solid darkmagenta; */
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #000;
}

p {
  font-size: 0.4rem;
}

.work-title {
  font-size: 0.7rem;
}

/* (Tablet) 750px-899px */
@media (min-width: 750px) and (max-width: 1024px) {
  .container {
    height: 25%;
    padding: 0 2%;
  }

  .bottom {
    height: auto;
    line-height: 1rem;
  }

  img {
    width: 30vh;
  }

  .work-title {
    font-size: 1.4rem;
  }

  p {
    font-size: 0.75rem;
  }
}

/* (Desktop) 750px and up */
@media (min-width: 1025px) {
  .Works {
    flex-direction: row;
  }

  .item {
    flex-direction: column;
    /* height: 100%; */
  }

  .container {
    height: 350px;
    padding: 0 2%;
  }

  .bottom {
    height: auto;
    line-height: 1rem;
  }

  img {
    width: 35vh;
  }

  .work-title {
    font-size: 1.4rem;
  }

  p {
    font-size: 0.75rem;
  }
}
