@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", Arial;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  height: 100vh;
  scroll-snap-align: start;
  filter: invert(85%);
}

.sections {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}
