.About {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  /* overflow-y: scroll; */
}

.bio {
  max-width: 75rem;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

h3 {
  font-size: 1.2em;
  line-height: 1.5;
  font-weight: 400;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .bio {
    padding: 6rem;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .bio {
    /* padding: 2rem; */
  }

  h1 {
    font-size: 1.3rem;
  }

  h3 {
    line-height: 1.5;
    font-weight: 300;
    font-size: 0.95rem;
  }
}
